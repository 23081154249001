import { call, put, takeEvery, all } from 'redux-saga/effects';

import BlockchainService from 'services/api/BlockchainApi';
import { getNotifications } from 'store/actions';
import myAccountApiService from 'services/api/MyAccountApi';
import voipApiService from 'services/api/VoipApi';
import {
	actionCreatorPreloaderOff,
	actionCreatorPreloaderOn,
	actionGetBankStatesPostDataResponse,
	actionGetBeneficiaryStatesPostDataResponse,
	actionGetBusinessTypePostDataResponse,
	actionGetCodecsPostDataResponse,
	actionGetCorrespondentStatesPostDataResponse,
	actionGetCountriesPostDataResponse,
	actionGetHardwareProfileOptionsPostDataResponse,
	actionGetPhonePrefixPostDataResponse,
	actionGetQrCodeResponse,
	actionGetStatesPostDataResponse,
	actionMyAccountGetDataResponse,
	changePasswordSuccess,
	lenderSettingsFail,
	lenderSettingsSuccess,
	loadVendorRates,
	myAccountLogoUploadFail,
	setExternalClients,
	setPostResponse,
	triggerNotification
} from '../actions';
import actions from './actions';
import awsAmplifyService from '../../services/api/awsamplify';

export function* sagaGetExternalClientData(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const result = yield call(myAccountApiService.getExternalClientDetails, action.payload);
		yield put(setExternalClients(result));
		yield put(actionCreatorPreloaderOff());
	} catch (e) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaUpdateExternalClientDetails(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		yield call(myAccountApiService.postExternalClientDetails, action.payload);
		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaLenderSettingsGetDataRequest() {
	try {
		const result = yield call(myAccountApiService.getLenderSettings, {});
		yield put(lenderSettingsSuccess(result));
	} catch (error) {
		yield put(lenderSettingsFail(error));
	}
}

export function* sagaLenderSettingsPostDataRequest(action) {
	try {
		const res = yield call(myAccountApiService.postLenderSettings, action.payload);
		yield put(
			lenderSettingsSuccess({
				info: 'Settings Successfully Saved',
				...action.payload.lenderSettings,
				...res
			})
		);
	} catch (error) {
		yield put(lenderSettingsFail('Invalid Configuration'));
	}
}

export function* sagaMyAccountPostRequest(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(myAccountApiService.postMyAccountData, action.payload);

		yield put(setPostResponse(result));
		yield put(getNotifications());

		const reloadData = yield call(myAccountApiService.getIndex, {});
		yield put(actionMyAccountGetDataResponse(reloadData));

		yield call(BlockchainService.postCreateParticipantWallet, action.payload);

		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaMyAccountGetRequest(action) {
	try {
		const result = yield call(myAccountApiService.getIndex, action.payload);

		const agreementResult = yield call(myAccountApiService.getSignPortalAgreementStatus, action.payload);
		const financingResult = yield call(myAccountApiService.getSignFinancingAgreementStatus, action.payload);

		result.agreement = agreementResult;
		if (financingResult && financingResult.url !== undefined) {
			result.financingAgreementUrl = financingResult.url;
		}

		if (agreementResult !== undefined && agreementResult.status === 'COMPLETED') {
			const platformAgreementUrl = yield call(myAccountApiService.getPortalAgreementPdf, action.payload);
			result.platformAgreementUrl = platformAgreementUrl.url;
		}

		yield put(actionMyAccountGetDataResponse(result));
	} catch (error) {
		yield put(actionMyAccountGetDataResponse(error));
	}
}

export function* sagaBankAccountPostRequest(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		let result = {};
		if (action.payload.bankAccount.id) {
			result = yield call(myAccountApiService.putBankAccountData, action.payload);
		} else {
			result = yield call(myAccountApiService.postBankAccountData, action.payload);
		}

		yield put(setPostResponse(result));

		const reloadData = yield call(myAccountApiService.getIndex, {});
		yield put(actionMyAccountGetDataResponse(reloadData));
		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaBankAccountRemovePostRequest(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(myAccountApiService.postRemoveBankAccountData, action.payload);

		yield put(setPostResponse(result));

		const reloadData = yield call(myAccountApiService.getIndex, {});
		yield put(actionMyAccountGetDataResponse(reloadData));
		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaNewHardwarePostRequest(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(myAccountApiService.postNewHardwareData, action.payload);

		const reloadData = yield call(myAccountApiService.getIndex, {});
		yield put(actionMyAccountGetDataResponse(reloadData));
		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaTechnicalDetailsPostRequest(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(myAccountApiService.postNewHardwareData, action.payload);

		if (result.status == 'OK') {
			if (action.payload.participant.carrierId) {
				// make request to voip/ip
				const ipAdressesResult = yield call(voipApiService.createIps, action.payload.participant.carrierId, result.hardwareProfile.ipAddresses);

				// fetch the response from voip/ip and save the id's
				const ipAddressIds = [];
				ipAdressesResult.map(resp => {
					ipAddressIds.push(resp.data.id);
				});

				const voipApiProfileData = {
					carrier: action.payload.participant.carrierId,
					ips: ipAddressIds,
					prefixes: result.hardwareProfile.techPrefix,
					trunk_grp_type: 'RFC4904Ingress'
				};

				// create the profile at voip/profile
				const voipApiProfileResponse = yield call(voipApiService.createProfile, voipApiProfileData);

				// lets save the profile in mongo only if the voip api handled the
				// whole request correctly
				if (voipApiProfileResponse.status == 201) {
					result.hardwareProfile.sbcId = voipApiProfileResponse.data.id;
					const updateData = {
						hardwareProfileData: result.hardwareProfile,
						participant: action.payload.participant
					};
					const updateResult = yield call(myAccountApiService.updateHardwareProfileSBC, updateData);

					yield put(setPostResponse(result));
					yield put(getNotifications());
				}
			}
		} else {
			yield put(setPostResponse(result));
			yield put(actionCreatorPreloaderOff());
		}

		const reloadData = yield call(myAccountApiService.getIndex, {});
		yield put(actionMyAccountGetDataResponse(reloadData));
		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(setPostResponse(error));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaCountriesGetRequest(action) {
	try {
		const result = yield call(myAccountApiService.getCountries, action.payload);
		yield put(actionGetCountriesPostDataResponse(result));
	} catch (error) {
		yield put(actionGetCountriesPostDataResponse(error));
	}
}

export function* sagaCodecsGetRequest(action) {
	try {
		const result = yield call(myAccountApiService.getCodecs, action.payload);
		yield put(actionGetCodecsPostDataResponse(result));
	} catch (error) {
		yield put(actionGetCodecsPostDataResponse(error));
	}
}

export function* sagaHardwareProfileOptionsGetRequest(action) {
	try {
		const result = yield call(myAccountApiService.getHardwareProfileOptions, action.payload);
		yield put(actionGetHardwareProfileOptionsPostDataResponse(result));
	} catch (error) {
		yield put(actionGetHardwareProfileOptionsPostDataResponse(error));
	}
}

export function* sagaStatesGetRequest(action) {
	try {
		const result = yield call(myAccountApiService.getStates, action.payload);
		yield put(actionGetStatesPostDataResponse(result));
	} catch (error) {
		yield put(actionGetStatesPostDataResponse(error));
	}
}

export function* sagaBankStatesGetRequest(action) {
	try {
		const result = yield call(myAccountApiService.getStates, action.payload);
		yield put(actionGetBankStatesPostDataResponse(result));
	} catch (error) {
		yield put(actionGetBankStatesPostDataResponse(error));
	}
}

export function* sagaBeneficiaryStatesGetRequest(action) {
	try {
		const result = yield call(myAccountApiService.getStates, action.payload);
		yield put(actionGetBeneficiaryStatesPostDataResponse(result));
	} catch (error) {
		yield put(actionGetBeneficiaryStatesPostDataResponse(error));
	}
}

export function* sagaCorrespondentStatesGetRequest(action) {
	try {
		const result = yield call(myAccountApiService.getStates, action.payload);
		yield put(actionGetCorrespondentStatesPostDataResponse(result));
	} catch (error) {
		yield put(actionGetCorrespondentStatesPostDataResponse(error));
	}
}

export function* sagaBusinessTypeGetRequest(action) {
	try {
		const result = yield call(myAccountApiService.getBusinessType, action.payload);
		yield put(actionGetBusinessTypePostDataResponse(result));
	} catch (error) {
		yield put(actionGetBusinessTypePostDataResponse(error));
	}
}

export function* sagaCreateNewWalletGetRequest(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(myAccountApiService.getCreateNewWallet, action.payload);
		yield put(getNotifications());
		const reloadData = yield call(myAccountApiService.getIndex, {});
		yield put(actionMyAccountGetDataResponse(reloadData));
		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaUpdateHardwareProfile(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(myAccountApiService.updateHardwareProfile, action.payload);

		if (result.status == 'OK') {
			if (action.payload.participant.carrierId) {
				// make request to voip/ip
				const ipAdressesResult = yield call(voipApiService.createIps, action.payload.participant.carrierId, result.hardwareProfile.ipAddresses);

				// fetch the response from voip/ip and save the id's
				const ipAddressIds = [];
				ipAdressesResult.map(resp => {
					ipAddressIds.push(resp.data.id);
				});

				const voipApiProfileData = {
					carrier: action.payload.participant.carrierId,
					ips: ipAddressIds,
					prefixes: result.hardwareProfile.techPrefix,
					trunk_grp_type: 'RFC4904Ingress'
				};

				let voipApiProfileResponse = false;
				// create the profile at voip/profile
				if (result.hardwareProfile.sbcId) {
					voipApiProfileResponse = yield call(voipApiService.updateProfile, result.hardwareProfile.sbcId, voipApiProfileData);
				} else {
					voipApiProfileResponse = yield call(voipApiService.createProfile, voipApiProfileData);
				}

				// lets save the profile in mongo only if the voip api handled the
				// whole request correctly
				if (voipApiProfileResponse && voipApiProfileResponse.status == 201) {
					result.hardwareProfile.sbcId = voipApiProfileResponse.data.id;
					const updateData = {
						hardwareProfileData: result.hardwareProfile,
						participant: action.payload.participant
					};
					const updateResult = yield call(myAccountApiService.updateHardwareProfileSBC, updateData);
					yield put(setPostResponse(result));
					yield put(getNotifications());
				}
			}
		} else {
			if (result && result.data && result.data.displayAdditionalMessage) {
				result.data.message = `${result.data.message} The system detected that some rates were incorrect. Please check the format of the uploaded rate file.`;
			}
			yield put(setPostResponse(result));
			yield put(actionCreatorPreloaderOff());
		}

		const reloadData = yield call(myAccountApiService.getIndex, {});
		yield put(actionMyAccountGetDataResponse(reloadData));
		const hardwareProfileId = action.payload && action.payload.hardwareProfileData && action.payload.hardwareProfileData._id;
		if (hardwareProfileId && action.payload.hardwareProfileData.type === 'egress') {
			yield put(loadVendorRates(action.payload.hardwareProfileData._id, {}));
		}

		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaRemoveHardwareProfile(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		yield call(myAccountApiService.removeHardwareProfile, action.payload);

		const reloadData = yield call(myAccountApiService.getIndex, {});
		yield put(getNotifications());
		yield put(actionMyAccountGetDataResponse(reloadData));
		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaPhonePrefixGetRequest(action) {
	try {
		const result = yield call(myAccountApiService.postPhonePrefix, action.payload);
		yield put(actionGetPhonePrefixPostDataResponse(result));
	} catch (error) {
		yield put(actionGetPhonePrefixPostDataResponse(error));
	}
}

export function* sagaHandleNewUser(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(myAccountApiService.inviteNewUser, action.payload);
		yield put(setPostResponse(result));
		if (result && result.status) {
			yield put(
				setPostResponse({
					status: 'OK',
					code: result.data,
					message: 'New user added'
				})
			);
		} else {
			yield put(
				setPostResponse({
					status: 'ERROR',
					code: result.data,
					message: 'Could not add user'
				})
			);
		}

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(setPostResponse(err));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaGetQrCode(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(myAccountApiService.getQrCode, action.payload);

		if (result.status && result.status === 'disabled' && result.success === true) {
			yield put(
				setPostResponse({
					status: 'ERROR',
					code: result.data,
					message: '2FA Disabled'
				})
			);
		} else {
			yield put(actionGetQrCodeResponse(result));

			yield put(
				setPostResponse({
					status: 'OK',
					code: result.data,
					message: '2FA Enabled - Please scan the QR Code'
				})
			);
		}

		const reloadData = yield call(myAccountApiService.getIndex, {});
		reloadData.mfaResponse = result;
		yield put(actionMyAccountGetDataResponse(reloadData));

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(setPostResponse(err));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaSignPortalAgreement(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(myAccountApiService.postSignPortalAgreement);
		if (result.signUrl !== undefined) {
			// there's probably a better way to do this!
			window.location.href = result.signUrl;
		}

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(setPostResponse(err));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaSignFinancingAgreement(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(myAccountApiService.postSignFinancingAgreement);

		if (result.signUrl !== undefined) {
			// there's probably a better way to do this!
			window.location.href = result.signUrl;
		}

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(setPostResponse(err));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaDownloadFinancingAgreement(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const agreementResult = yield call(myAccountApiService.getSignPortalAgreementStatus, action.payload);

		if (agreementResult !== undefined && agreementResult.status === 'COMPLETED') {
			const agreementPdf = yield call(myAccountApiService.getPortalAgreementPdf, action.payload);
			window.location.href = agreementPdf.url;
		}

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(setPostResponse(err));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaDownloadPortalAgreement(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const agreementResult = yield call(myAccountApiService.getSignPortalAgreementStatus, action.payload);

		if (agreementResult !== undefined && agreementResult.status === 'COMPLETED') {
			const agreementPdf = yield call(myAccountApiService.getPortalAgreementPdf, action.payload);
			window.location.href = agreementPdf.url;
		}

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(setPostResponse(err));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaUploadLogo(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const uploadLogo = yield call(myAccountApiService.uploadLogo, action.payload);

		yield put(setPostResponse(uploadLogo.data));

		const reloadData = yield call(myAccountApiService.getIndex, {});
		yield put(actionMyAccountGetDataResponse(reloadData));
		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(setPostResponse(err));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaChangePasswordPostRequest(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const result = yield call(myAccountApiService.postChangePassword, action.payload);

		yield put(setPostResponse(result));
		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(setPostResponse(error));
		yield put(actionCreatorPreloaderOff());
	}
}

// New way
export function* GET_COUNTRIES() {
	try {
		const result = yield call(myAccountApiService.getCountries);
		if (result) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					countries: result
				}
			});
		}
		//
	} catch (error) {}
}
export function* GET_STATES({ payload }) {
	try {
		const result = yield call(myAccountApiService.getStates, payload);
		if (result) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					states: result
				}
			});
		}
		//
	} catch (error) {}
}
export function* GET_ALL_STATES() {
	try {
		const result = yield call(myAccountApiService.getStates, 0);
		if (result) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					allStates: result
				}
			});
		}
		//
	} catch (error) {}
}

export function* GET_BUSINESS_TYPES() {
	try {
		const result = yield call(myAccountApiService.getBusinessTypes);
		if (result) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					businessTypes: result
				}
			});
		}
		//
	} catch (error) {}
}

export function* GET_CURRENCIES() {
	try {
		const result = yield call(myAccountApiService.getCurrencies);
		if (result) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					currencies: result
				}
			});
		}
		//
	} catch (error) {}
}

export function* GET_ACCOUNT_DETAILS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getAccountDetails);
		if (result) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					accountData: result
				}
			});
		}
		//
	} catch (error) {
		console.log('GET_ACCOUNT_DATA ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_ACCOUNT_DETAILS({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(myAccountApiService.updateAccountDetails, payload);
		if (result) {
			yield put({ type: 'myaccount/GET_ACCOUNT_DETAILS' });
		}
		//
	} catch (error) {
		console.log('GET_ACCOUNT_DATA ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_ACCOUNT_PASSWORD({ payload: { oldPassword, newPassword, email } }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(awsAmplifyService.changePassword, oldPassword, newPassword, email);
		yield put(
			triggerNotification(
				result.message || (result.status === 'OK' && 'Password updated succesfully') || 'Unable to change password. Please try again later',
				(result.status === 'OK' && 'success') || 'error'
			)
		);
	} catch (error) {
		console.log('UPDATE_ACCOUNT_PASSWORD ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_BANK_ACCOUNTS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingBankAccounts: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getBankAccounts);
		if (result) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					bankAccounts: result
				}
			});
		}
		//
	} catch (error) {
		console.log('GET_ACCOUNT_DATA ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingBankAccounts: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* CREATE_NEW_BANK_ACCOUNT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingBankAccounts: true
		}
	});
	try {
		const result = yield call(myAccountApiService.createNewBankAccount, payload);
		if (result) {
			yield put({ type: 'myaccount/GET_BANK_ACCOUNTS' });
		}
		//
	} catch (error) {
		console.log('GET_ACCOUNT_DATA ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingBankAccounts: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_BANK_ACCOUNT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingBankAccounts: true
		}
	});
	try {
		const result = yield call(myAccountApiService.updateBankAccount, payload);
		if (result) {
			yield put({ type: 'myaccount/GET_BANK_ACCOUNTS' });
		}
		//
	} catch (error) {
		console.log('GET_ACCOUNT_DATA ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingBankAccounts: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DELETE_BANK_ACCOUNT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingBankAccounts: true
		}
	});
	try {
		const result = yield call(myAccountApiService.deleteBankAccount, payload);
		if (result) {
			yield put({ type: 'myaccount/GET_BANK_ACCOUNTS' });
		}
		//
	} catch (error) {
		console.log('GET_ACCOUNT_DATA ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingBankAccounts: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_PERMISSIONS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingBankAccounts: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getUserPermissions);
		if (result) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					permissions: result
				}
			});
		}
		//
	} catch (error) {
		console.log('GET_ACCOUNT_DATA ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingBankAccounts: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_USER_ACCOUNTS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingUserAccounts: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getUserSubAccounts);
		if (result && Array.isArray(result)) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					userAccounts: result
				}
			});
		}
		//
	} catch (error) {
		console.log('GET_USER_ACCOUNTS ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingUserAccounts: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* CREATE_NEW_SUBACOUNT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingUserAccounts: true
		}
	});
	try {
		const result = yield call(myAccountApiService.createNewSubAccount, payload);
		if (result && result.user) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					createUserSuccess: true
				}
			});
			yield put(triggerNotification(result.message || 'Successfully created account.', 'success'));
			yield put({ type: 'myaccount/GET_USER_ACCOUNTS' });
		} else {
			yield put(
				triggerNotification(
					(result && result.response && result.response.data && result.response.data.message) || result.message || 'Could not create new account',
					'error'
				)
			);
		}
		//
	} catch (error) {
		console.log('CREATE_NEW_SUBACOUNT ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingUserAccounts: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_USER_SUBACOUNT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingUserAccounts: true
		}
	});
	try {
		const result = yield call(myAccountApiService.updateSubAccount, payload);
		if (result && result.user) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					updateUserSuccess: true
				}
			});
			yield put(triggerNotification(result.message || 'Successfully updated account.', 'success'));
			yield put({ type: 'myaccount/GET_USER_ACCOUNTS' });
		} else {
			yield put(
				triggerNotification((result && result.response && result.response.data && result.response.data.message) || result.message || 'Could not update account', 'error')
			);
		}
		//
	} catch (error) {
		console.log('UPDATE_USER_SUBACOUNT ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingUserAccounts: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* REMOVE_USER_SUBACOUNT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingUserAccounts: true
		}
	});
	try {
		const result = yield call(myAccountApiService.deleteSubAccount, payload);
		if (result && result.user) {
			yield put(triggerNotification(result.message || 'Successfully removed account.', 'success'));
			yield put({ type: 'myaccount/GET_USER_ACCOUNTS' });
		} else {
			yield put(
				triggerNotification((result && result.response && result.response.data && result.response.data.message) || result.message || 'Could not remove account', 'error')
			);
		}
		//
	} catch (error) {
		console.log('REMOVE_USER_SUBACOUNT ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingUserAccounts: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ACCOUNT_MFA_STATUS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingSecurity: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getUserMFAStatus);
		if (result && result.active !== undefined) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					accountMfaActive: result.active
				}
			});
		}
	} catch (error) {
		console.log('GET_ACCOUNT_MFA_STATUS ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingSecurity: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ACCOUNT_MFA_QR_CODE() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingSecurity: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getUserMFAQRCode);
		if (result) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					accountMfaQRCodeData: result
				}
			});
		}
	} catch (error) {
		console.log('GET_ACCOUNT_MFA_QR_CODE ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingSecurity: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_ACCOUNT_MFA_ENABLE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingSecurity: true
		}
	});
	try {
		const result = yield call(myAccountApiService.updateUserEnableMFA, payload);
		if (result) {
			yield put(
				triggerNotification(
					result.message || (result.status === 'OK' && 'Two step verification enabled successfully') || 'Unable to enable two step verification. Please try again later',
					(result.status === 'OK' && 'success') || 'error'
				)
			);
			yield put({ type: 'myaccount/GET_ACCOUNT_MFA_STATUS' });
		}
	} catch (error) {
		console.log('UPDATE_ACCOUNT_MFA_ENABLE ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingSecurity: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_ACCOUNT_MFA_DISABLE() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingSecurity: true
		}
	});
	try {
		const result = yield call(myAccountApiService.updateUserDisableMFA);
		if (result) {
			yield put(
				triggerNotification(
					result.message || (result.status === 'OK' && 'Two step verification enabled successfully') || 'Unable to enable two step verification. Please try again later',
					(result.status === 'OK' && 'success') || 'error'
				)
			);
			yield put({ type: 'myaccount/GET_ACCOUNT_MFA_STATUS' });
		}
	} catch (error) {
		console.log('UPDATE_ACCOUNT_MFA_ENABLE ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingSecurity: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_PLATFORM_AGREEMENT_STATUS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingPlatformAgreement: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getPlatformAgreementStatus);
		if (result && result.status) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					platformAgreementStatus: result
				}
			});
		}
	} catch (error) {
		console.log('GET_PLATFORM_AGREEMENT_STATUS ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingPlatformAgreement: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_PLATFORM_AGREEMENT_INITIATE() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingPlatformAgreement: true
		}
	});
	try {
		const result = yield call(myAccountApiService.initiatePlatformAgreement);
		if (result && result.signUrl) {
			window.location.href = result.signUrl;
		} else {
			yield put(triggerNotification('Unable to sign portal agreement at the moment. Please try again later', 'error'));
		}
	} catch (error) {
		console.log('GET_PLATFORM_AGREEMENT_STATUS ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingPlatformAgreement: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_FINANCING_AGREEMENT_STATUS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingFinancingAgreement: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getFinancingAgreementStatus);
		if (result && result.status) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					financingAgreementStatus: result
				}
			});
		}
	} catch (error) {
		console.log('GET_FINANCING_AGREEMENT_STATUS ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingFinancingAgreement: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_FINANCING_AGREEMENT_INITIATE() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingFinancingAgreement: true
		}
	});
	try {
		const result = yield call(myAccountApiService.initiateFinancingAgreement);
		if (result && result.signUrl) {
			window.location.href = result.signUrl;
		} else {
			yield put(triggerNotification('Unable to sign financing agreement at the moment. Please try again later', 'error'));
		}
	} catch (error) {
		console.log('GET_FINANCING_AGREEMENT_STATUS ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingFinancingAgreement: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ACP_PLATFORM_AGREEMENT_STATUS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingAcpPlatformAgreement: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getAcpPlatformAgreementStatus);
		if (result && result.status) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					acpPlatformAgreementStatus: result
				}
			});
		}
	} catch (error) {
		console.log('GET_ACP_PLATFORM_AGREEMENT_STATUS ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingAcpPlatformAgreement: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ACP_PLATFORM_AGREEMENT_INITIATE() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingAcpPlatformAgreement: true
		}
	});
	try {
		const result = yield call(myAccountApiService.initiateAcpPlatformAgreement);
		if (result && result.signUrl) {
			window.location.href = result.signUrl;
		} else {
			yield put(triggerNotification('Unable to sign portal agreement at the moment. Please try again later', 'error'));
		}
	} catch (error) {
		console.log('GET_ACP_PLATFORM_AGREEMENT_INITIATE ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingAcpPlatformAgreement: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ACP_FINANCING_AGREEMENT_STATUS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingAcpFinancingAgreement: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getAcpFinancingAgreementStatus);
		if (result && result.status) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					acpFinancingAgreementStatus: result
				}
			});
		}
	} catch (error) {
		console.log('GET_ACP_FINANCING_AGREEMENT_STATUS ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingAcpFinancingAgreement: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ACP_FINANCING_AGREEMENT_INITIATE() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingAcpFinancingAgreement: true
		}
	});
	try {
		const result = yield call(myAccountApiService.initiateAcpFinancingAgreement);
		if (result && result.signUrl) {
			window.location.href = result.signUrl;
		} else {
			yield put(triggerNotification('Unable to sign financing agreement at the moment. Please try again later', 'error'));
		}
	} catch (error) {
		console.log('GET_ACP_FINANCING_AGREEMENT_INITIATE ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingAcpFinancingAgreement: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_CONTRACT_AGREEMENTS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingContractAgreements: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getContractAgreements);
		if (result) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					contractAgreementsList: result
				}
			});
		}
	} catch (error) {
		console.log('GET_CONTRACT_AGREEMENTS ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingContractAgreements: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_CONTRACT_AGREEMENT_BY_GUID({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingContractAgreements: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getContractAgreementByGUID, payload);
		if (result && result.status === 'COMPLETED' && result.agreementUrl) {
			window.location.href = result.agreementUrl;
		} else {
			yield put(triggerNotification('Unable to download agreement at the moment. Please try again later', 'error'));
		}
	} catch (error) {
		console.log('GET_CONTRACT_AGREEMENT_BY_GUID ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingContractAgreements: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* GET_LENDER_SETTINGS_DATA() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingLenderSettings: true
		}
	});
	try {
		const result = yield call(myAccountApiService.getLenderSettingsData);
		if (result) {
			console.log('result', result);
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					lenderSettingsData: result
				}
			});
		}
	} catch (error) {
		console.log('GET_CONTRACT_AGREEMENT_BY_GUID ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingLenderSettings: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DOWNLOAD_LEGAL_DOCUMENT({ payload }) {
	const { id } = payload;

	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: true,
			loadingLegalDocs: true
		}
	});

	const response = yield call(myAccountApiService.downloadDocument, id);
	if (!response && response.status !== 200) {
		yield put(triggerNotification(result.response.data.message || 'Unable to download this document', 'error'));
	}

	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: false,
			loadingLegalDocs: false
		}
	});
}

export function* GET_API_KEYS() {
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: true,
		}
	});
	try {
		const response = yield call(myAccountApiService.getApiKeys);
		if (response) {
			yield put({
				type: 'myaccount/SET_STATE',
				payload: {
					apiKeys: response
				}
			});
		}
	} catch (error) {
		console.log('GET_API_KEYS ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: false,
		}
	});
}

export function* GET_LEGAL_DOCUMENTS() {
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: true,
			loadingLegalDocs: true
		}
	});

	const response = yield call(myAccountApiService.getLegalDocuments);

	if (response) {
		yield put({
			type: 'myaccount/SET_STATE',
			payload: {
				legalDocumentsList: response
			}
		});
	}

	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loading: false,
			loadingLegalDocs: false
		}
	});
}

export function* SET_LENDER_SETTINGS_DATA({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingLenderSettings: true
		}
	});
	try {
		const result = yield call(myAccountApiService.setLenderSettingsData, payload);
		if (result && result.response && result.response.data && result.response.data.code && result.response.data.code !== 200) {
			yield put(triggerNotification(result.response.data.message || 'Unable to update lender settings', 'error'));
		} else {
			yield put(triggerNotification('Settings saved succesfully', 'success'));
			yield put({ type: 'myaccount/GET_LENDER_SETTINGS_DATA' });
		}
	} catch (error) {
		console.log('GET_CONTRACT_AGREEMENT_BY_GUID ERROR');
	}
	yield put({
		type: 'myaccount/SET_STATE',
		payload: {
			loadingLenderSettings: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export default function* rootSaga() {
	// Misc
	yield all([takeEvery(actions.GET_COUNTRIES, GET_COUNTRIES)]);
	yield all([takeEvery(actions.GET_STATES, GET_STATES)]);
	yield all([takeEvery(actions.GET_ALL_STATES, GET_ALL_STATES)]);
	yield all([takeEvery(actions.GET_BUSINESS_TYPES, GET_BUSINESS_TYPES)]);
	yield all([takeEvery(actions.GET_CURRENCIES, GET_CURRENCIES)]);

	// Account data
	yield all([takeEvery(actions.GET_PERMISSIONS, GET_PERMISSIONS)]);
	yield all([takeEvery(actions.GET_ACCOUNT_DETAILS, GET_ACCOUNT_DETAILS)]);
	yield all([takeEvery(actions.UPDATE_ACCOUNT_DETAILS, UPDATE_ACCOUNT_DETAILS)]);
	yield all([takeEvery(actions.UPDATE_ACCOUNT_PASSWORD, UPDATE_ACCOUNT_PASSWORD)]);

	yield all([takeEvery(actions.GET_BANK_ACCOUNTS, GET_BANK_ACCOUNTS)]);
	yield all([takeEvery(actions.CREATE_NEW_BANK_ACCOUNT, CREATE_NEW_BANK_ACCOUNT)]);
	yield all([takeEvery(actions.UPDATE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT)]);
	yield all([takeEvery(actions.DELETE_BANK_ACCOUNT, DELETE_BANK_ACCOUNT)]);

	// User administration
	yield all([takeEvery(actions.GET_USER_ACCOUNTS, GET_USER_ACCOUNTS)]);
	yield all([takeEvery(actions.CREATE_NEW_SUBACOUNT, CREATE_NEW_SUBACOUNT)]);
	yield all([takeEvery(actions.UPDATE_USER_SUBACOUNT, UPDATE_USER_SUBACOUNT)]);
	yield all([takeEvery(actions.REMOVE_USER_SUBACOUNT, REMOVE_USER_SUBACOUNT)]);

	// Security
	yield all([takeEvery(actions.GET_ACCOUNT_MFA_STATUS, GET_ACCOUNT_MFA_STATUS)]);
	yield all([takeEvery(actions.GET_ACCOUNT_MFA_QR_CODE, GET_ACCOUNT_MFA_QR_CODE)]);
	yield all([takeEvery(actions.UPDATE_ACCOUNT_MFA_ENABLE, UPDATE_ACCOUNT_MFA_ENABLE)]);
	yield all([takeEvery(actions.UPDATE_ACCOUNT_MFA_DISABLE, UPDATE_ACCOUNT_MFA_DISABLE)]);

	// Legal agreements
	yield all([takeEvery(actions.GET_PLATFORM_AGREEMENT_STATUS, GET_PLATFORM_AGREEMENT_STATUS)]);
	yield all([takeEvery(actions.GET_PLATFORM_AGREEMENT_INITIATE, GET_PLATFORM_AGREEMENT_INITIATE)]);
	yield all([takeEvery(actions.GET_FINANCING_AGREEMENT_STATUS, GET_FINANCING_AGREEMENT_STATUS)]);
	yield all([takeEvery(actions.GET_FINANCING_AGREEMENT_INITIATE, GET_FINANCING_AGREEMENT_INITIATE)]);
	yield all([takeEvery(actions.GET_ACP_PLATFORM_AGREEMENT_STATUS, GET_ACP_PLATFORM_AGREEMENT_STATUS)]);
	yield all([takeEvery(actions.GET_ACP_PLATFORM_AGREEMENT_INITIATE, GET_ACP_PLATFORM_AGREEMENT_INITIATE)]);
	yield all([takeEvery(actions.GET_ACP_FINANCING_AGREEMENT_STATUS, GET_ACP_FINANCING_AGREEMENT_STATUS)]);
	yield all([takeEvery(actions.GET_ACP_FINANCING_AGREEMENT_INITIATE, GET_ACP_FINANCING_AGREEMENT_INITIATE)]);
	yield all([takeEvery(actions.GET_CONTRACT_AGREEMENTS, GET_CONTRACT_AGREEMENTS)]);
	yield all([takeEvery(actions.GET_CONTRACT_AGREEMENT_BY_GUID, GET_CONTRACT_AGREEMENT_BY_GUID)]);

	// Legal Documents
	yield all([takeEvery(actions.GET_LEGAL_DOCUMENTS, GET_LEGAL_DOCUMENTS)])
	yield all([takeEvery(actions.DOWNLOAD_LEGAL_DOCUMENT, DOWNLOAD_LEGAL_DOCUMENT)])

	// API keys
	yield all([takeEvery(actions.GET_API_KEYS, GET_API_KEYS)]);

	// Lender settings
	yield all([takeEvery(actions.GET_LENDER_SETTINGS_DATA, GET_LENDER_SETTINGS_DATA)]);
	yield all([takeEvery(actions.SET_LENDER_SETTINGS_DATA, SET_LENDER_SETTINGS_DATA)]);
}
