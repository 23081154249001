import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, change } from 'redux-form';
import { Link, withRouter } from 'react-router-dom';
import { RenderInput, RenderButton, RenderNotification, RenderRadioGroup, RenderRadio, RenderCheckbox } from 'components';

import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Grid,
	InputAdornment,
	IconButton,
	Dialog,
	DialogContent,
	AppBar,
	Slide,
	Typography,
	Toolbar,
	Paper,
	Box
} from '@material-ui/core';

import { Person, Email, Visibility, VisibilityOff, LockOpen, Help, Book, Close, Work, Phone } from '@material-ui/icons';

import registerStyle from './style';

const Transition = props => <Slide direction="up" {...props} />;

class RegisterForm extends Component {
	constructor(props) {
		super(props);
	}
	state = {
		showPassword: false,
		modalOpen: false,
		accessType: null
	};

	openTos = () => {
		this.setState({ modalOpen: true });
	};

	closeTos = () => {
		this.setState({ modalOpen: false });
	};

	handleMouseDownPassword = event => {
		event.preventDefault();
	};

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	};

	componentDidMount() {
		//********* */ EXTRACTING VARIABLES FROM QUERY STRING *********//
		const searchParams = new URLSearchParams(this.props.location.search);
		const accessType = searchParams.get('accessType');
		const contract = searchParams.get('contract');

		this.props.initialize({
			accessType: accessType ?? null,
			contract: contract ? atob(contract) : null,
			accountType: '1'
		});

		this.setState({
			accessType
		});
	}

	hiddenInput = ({ input: { value, onChange }, ...rest }) => <input type="hidden" value={value} onChange={onChange} {...rest} />;

	render() {
		const { handleSubmit, pristine, reset, submitting, classes } = this.props;
		const { accessType } = this.state;

		return (
			<form onSubmit={handleSubmit} className={classes.form}>
				<Card className={classes.root}>
					<CardContent>
						<img src="/images/Portal_Logo.png" alt="Logo TessPay" />
						<br />
						<br />

						<Field name="accessType" component={this.hiddenInput} />
						<Field name="contract" component={this.hiddenInput} />

						{accessType ? (
							<Box className={classes.headerText}>
								<Typography variant="h6">Welcome!</Typography>
								<Typography variant="body2"> You have been invited as a Partner for the ACP program</Typography>
							</Box>
						) : (
							<Field name="accountType" component={RenderRadioGroup} inline>
								<RenderRadio value="1" label="Carrier" />
								<RenderRadio value="3" label="Manufacturer"/>
								<RenderRadio value="2" label="Lender" />
							</Field>
						)}

						<Grid container>
							<Grid item xs={12}>
								<Field
									name="companyName"
									label="Company Name"
									type="text"
									component={RenderInput}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton disabled>
													<Work />
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									name="contactName"
									label="Contact Name"
									type="text"
									component={RenderInput}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton disabled>
													<Person />
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									name="email"
									label="E-Mail Address"
									type="email"
									component={RenderInput}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton disabled>
													<Email />
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									name="phone"
									label="Phone Number"
									type="number"
									component={RenderInput}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton disabled>
													<Phone />
												</IconButton>
											</InputAdornment>
										),
										inputMode: 'numeric'
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									name="password"
									label="Password"
									type={this.state.showPassword ? 'text' : 'password'}
									component={RenderInput}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Toggle password visibility"
													onClick={this.handleClickShowPassword}
													onMouseDown={this.handleMouseDownPassword}
												>
													{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									name="passwordConfirmation"
									label="Confirm Password"
									type={this.state.showPassword ? 'text' : 'password'}
									component={RenderInput}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Toggle password visibility"
													onClick={this.handleClickShowPassword}
													onMouseDown={this.handleMouseDownPassword}
												>
													{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
						</Grid>

						{/* <Field
              name="agree"
              label="I have read and agreed to the terms of service"
              component={RenderCheckbox}
              value="1"
              ref={input => (this.agreementInput = input)}
            /> */}
						<RenderNotification variant="danger" floating={false} open={this.props.info.errorMessage} message={this.props.info.errorMessage} />
					</CardContent>
					<CardActions>
						<Grid container justify="center" alignItems="center">
							<Grid item>
								<RenderButton color="primary" type="submit">
									Register
								</RenderButton>
							</Grid>
							<Grid item xs={12}>
								<center>
									<RenderButton component={Link} to="/login" className="js-t-forgot-pw" underlined>
										Already have an account
									</RenderButton>
									{/* <RenderButton onClick={this.openTos}>
                    Terms of service
                  </RenderButton> */}
								</center>
							</Grid>
						</Grid>
					</CardActions>
				</Card>
				<Dialog fullScreen open={this.state.modalOpen} onClose={this.closeTos} TransitionComponent={Transition}>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton color="inherit" onClick={this.closeTos} aria-label="Close">
								<Close />
							</IconButton>
							<Typography variant="h1" color="inherit" className={classes.flex}>
								Terms of service
							</Typography>
							<RenderButton color="warning" onClick={this.closeTos}>
								Close
							</RenderButton>
						</Toolbar>
					</AppBar>
					<DialogContent>
						<Typography variant="display1" align="center" className={classes.tosTitle}>
							What is Lorem Ipsum?
						</Typography>
						<Paper className={classes.paper}>
							<Typography variant="h2" gutterBottom>
								<strong>1</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>2</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>3</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>4</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>5</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>6</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>7</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>8</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>9</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>10</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>11</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>12</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>13</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>14</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>15</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
							<Typography variant="h2" gutterBottom>
								<strong>16</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
								five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
								Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
								Ipsum.
							</Typography>
						</Paper>
					</DialogContent>
				</Dialog>
			</form>
		);
	}
}

export default withRouter(withStyles(registerStyle)(RegisterForm));
