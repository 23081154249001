import Amplify, { Auth } from 'aws-amplify';
import {
	ChangePasswordPage,
	FinancialPage,
	FinancialPage2,
	InfoTessPayPage,
	TradingPartnersPage,
	LoginPage,
	MFAPage,
	MyAccountPage,
	PasswordResetPage,
	RegisterPage,
	ReportPage,
	RoutesEditPage,
	RoutesPage,
	SmartContractEditPage,
	SmartContractNewPage,
	SmartContractNewPage2,
	SmartContractUpdatePage2,
	VerificationCodePage,
	WalletTessPayPage,
	WelcomePage,
	ImpersonateAuthPage,
	SmartContractsPage,
	AcpProgramPage,
	SupportTessPayPage,
	PasswordChallengePage,
	AcpSmartContractsPage
} from 'components';
import { InterconnectAcceptContainer, ValidateURLContainer } from 'containers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import customTheme from 'assets/customTheme';

Amplify.configure({
	Auth: {
		region: window.config?.cognito?.region || 'us-east-1',
		userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID || window.config?.cognito?.userPoolId,
		userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || window.config?.cognito?.clientId,
		identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID || window.config?.cognito?.identityPoolId,
		mandatorySignIn: false
	},
	API: {
		endpoints: [
			{
				name: 'NODE_SERVICE_API',
				endpoint: process.env.REACT_APP_MAIN_SERVICE_API_URL || window.config?.mainServiceApi,
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'ACCOUNT_SERVICE_API',
				endpoint: process.env.REACT_APP_ACCOUNT_SERVICE_API_URL || window.config?.accountServiceApi,
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'ROUTING_SERVICE_API',
				endpoint: process.env.REACT_APP_ROUTING_SERVICE_API_URL || window.config?.routingServiceApi,
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'ACP_SERVICE_API',
				endpoint: process.env.REACT_APP_ACP_SERVICE_API_URL || window.config?.acpServiceApi,
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'REPORTS_SERVICE_API',
				endpoint: process.env.REACT_APP_REPORTS_SERVICE_API_URL || window.config?.reportsServiceApi,
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'CONTRACT_SERVICE_API',
				endpoint: process.env.REACT_APP_SMART_CONTRACT_SERVICE_API_URL || window.config?.smartContractServiceApi,
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'FINANCIALS_SERVICE_API',
				endpoint: process.env.REACT_APP_FINANCIALS_SERVICE_API_URL || window.config?.financialsServiceApi,
				region: 'us-east-1',
				custom_header: currentUserToken
			},

			{
				name: 'MY_ACCOUNT_API',
				endpoint: 'https://urm3ple0vh.execute-api.us-east-1.amazonaws.com/dev',
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'WALLET_API',
				endpoint: 'https://urm3ple0vh.execute-api.us-east-1.amazonaws.com/dev/wallet',
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'NOTIFICATION_API',
				endpoint: 'https://urm3ple0vh.execute-api.us-east-1.amazonaws.com/dev/notification',
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'BLOCK_CHAIN_API',
				endpoint: 'https://urm3ple0vh.execute-api.us-east-1.amazonaws.com/dev/api',
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'FINANCIAL_API',
				endpoint: 'https://urm3ple0vh.execute-api.us-east-1.amazonaws.com/dev/financial',
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'BIDDING_API',
				endpoint: 'https://urm3ple0vh.execute-api.us-east-1.amazonaws.com/dev/bidding-portal',
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'INTERCONNECTION_API',
				endpoint: 'https://urm3ple0vh.execute-api.us-east-1.amazonaws.com/dev/interconnect',
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'SMARTCONTRACTAPI',
				endpoint: 'https://urm3ple0vh.execute-api.us-east-1.amazonaws.com/dev/smartcontracts',
				region: 'us-east-1',
				custom_header: currentUserToken
			},

			{
				name: 'REPORT_API',
				endpoint: 'https://urm3ple0vh.execute-api.us-east-1.amazonaws.com/dev/reports',
				region: 'us-east-1',
				custom_header: currentUserToken
			},
			{
				name: 'CONTENT_API',
				endpoint: 'https://urm3ple0vh.execute-api.us-east-1.amazonaws.com/dev/admin/manage',
				region: 'us-east-1',
				custom_header: currentUserToken
			}
		]
	}
});

function currentUserToken() {
	return Auth.currentAuthenticatedUser()
		.then(user => ({
			AuthorizationInfo: user.signInUserSession.idToken.jwtToken,
			impersonateCode: window.localStorage.getItem('IMPERSONATE_CODE') || ''
		}))
		.catch(e => console.log(e));
}

class PrivateRoute extends React.Component {
	constructor(props) {
		super(props);

		this.getSession = this.getSession.bind(this);
	}

	getSession() {
		const userPoolData = new CognitoUserPool({
			UserPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID || window.config?.cognito?.userPoolId,
			ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || window.config?.cognito?.clientId
		});

		const cognitoUser = userPoolData.getCurrentUser();
		return cognitoUser != null;
	}

	render() {
		const { component: Component, ...rest } = this.props;

		return <Route {...rest} render={props => (!this.getSession() ? <Redirect to="/login" /> : <Component {...props} />)} />;
	}
}

const App = () => (
	<div id="app-main">
		<MuiThemeProvider theme={customTheme}>
			<SnackbarProvider
				maxSnack={5}
				domRoot={document.getElementById('#app-main')}
				preventDuplicate
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'top'
				}}
			>
				<CssBaseline />
				{/* <Route> */}
				<Switch>
					<Route exact path="/" render={() => <Redirect to="/login" />} />
					<Route path="/login" component={props => <LoginPage {...props} />} />
					<Route path="/mfa" component={props => <MFAPage {...props} />} />
					<Route path="/register" component={props => <RegisterPage {...props} />} />
					<Route path="/reset" component={props => <PasswordResetPage {...props} />} />
					<Route path="/verification" component={props => <VerificationCodePage {...props} />} />
					<Route path="/password-challenge" component={props => <PasswordChallengePage {...props} />} />

					<Route path="/impersonate/:code" component={props => <ImpersonateAuthPage {...props} />} />

					<PrivateRoute path="/dashboard" component={WelcomePage} />
					<Route path="/changepassword" component={props => <ChangePasswordPage {...props} />} />
					<PrivateRoute path="/account" component={MyAccountPage} />
					<PrivateRoute path="/account/index/:status" component={MyAccountPage} />
					<Route path="/validate/:token" component={props => <ValidateURLContainer {...props} />} />
					<PrivateRoute path="/trading-partners" component={TradingPartnersPage} />
					<PrivateRoute path="/info/index" component={InfoTessPayPage} />
					<PrivateRoute path="/wallet" component={WalletTessPayPage} />
					<PrivateRoute path="/reports" component={ReportPage} />

					<PrivateRoute path="/routing" exact component={RoutesPage} />
					<PrivateRoute path="/routing/:smartContractId" component={RoutesEditPage} />

					{/*<PrivateRoute path="/financials2" component={FinancialPage} />*/}
					<PrivateRoute path="/interconnect/accept-from-link/:guid" component={InterconnectAcceptContainer} />

					<PrivateRoute path="/financials" component={FinancialPage2} />
					<PrivateRoute path="/acp-smart-contracts" component={AcpSmartContractsPage} />
					<PrivateRoute path="/smart-contracts" exact component={SmartContractsPage} />
					<PrivateRoute path="/smart-contracts/new" exact component={SmartContractNewPage} />
					<PrivateRoute path="/smart-contracts/new2" exact component={SmartContractNewPage2} />
					<PrivateRoute path="/smart-contracts/new2/:tempId" exact component={SmartContractNewPage2} />
					<PrivateRoute path="/smart-contracts/update/:smartContractid" component={SmartContractUpdatePage2} />
					<PrivateRoute path="/smart-contracts/:smartContractid" component={SmartContractEditPage} />
					{/*<PrivateRoute path="/smart-contracts/funding-offers/:smartContractid" component={SmartContractLendingListPage} />*/}

					{/*<PrivateRoute path="/financing-requests/index" component={FinancingRequestsPage} />*/}
					<PrivateRoute path="/acp-program/:tab" component={AcpProgramPage} />
					<PrivateRoute path="/support" component={SupportTessPayPage} />

					<Redirect to="/account/general-information" />
				</Switch>
				{/* </Route> */}
			</SnackbarProvider>
		</MuiThemeProvider>
	</div>
);

export default App;
