const privateMenuStyle = theme => ({
  navMenu: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.light,
    width: theme.spacing(1) * 5,
    transition: "width 225ms ease"
  },
  navMenuOpen: {
    width: "200px"
  },
  list: {
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      display: "initial"
    }
  },
  hamburger: {
    padding: theme.spacing(1),
    textAlign: "right",
    height: theme.spacing(1) * 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  navFixed: {
    position: "fixed",
    top: theme.spacing(1) * 6,
    bottom: 0
  }
});

export default privateMenuStyle;
