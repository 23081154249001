export const SELECT_MENU_ITEM = "SELECT_MENU_ITEM";
export const MENU = "MENU";
export const OPEN_MENU = "OPEN_MENU";
export const MENU_OPEN = "MENU_OPEN";

export const actionCreatorSelectItem = data => ({
  type: SELECT_MENU_ITEM,
  payload: data
});

export const actionCreatorGetMenuState = data => ({
  type: OPEN_MENU,
  payload: data
});
