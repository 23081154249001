import { SELECT_MENU_ITEM, OPEN_MENU, MENU, MENU_OPEN } from "./actions";

const initialState = {
	MENU_OPEN: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SELECT_MENU_ITEM:
      return {
        ...state,
        [MENU]: {
          selected: action.payload
        }
      };
    case OPEN_MENU:
      return {
        ...state,
        [MENU_OPEN]: {
          open: action.payload
        }
      };
    default:
      return state;
  }
}
